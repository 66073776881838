import React, { Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PIPELINE_ID_PARAM } from '@cb/product-react/Hooks/UseParam';
import AiLabPage from '../../../Components/Layout/AiLabPage';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';
import InlineSpinner from '@cb/solaris-react/Components/Loading/InlineSpinner';
const Pipeline = React.lazy(() => import('./Pipeline/Pipeline'));
const Pipelines = React.lazy(() => import('./Pipelines'));

interface PipelinesPageProps {
	raw?: boolean;
}

export default function PipelinesPage(props: PipelinesPageProps) {
	const { path } = useRouteMatch();
	const { raw } = props;

	return (
		<AiLabPage pageTitle="Pipelines" raw={raw} projectRoute={ProjectRoutes.Pipelines}>
			<Suspense fallback={<InlineSpinner overlay />}>
				<Switch>
					<Route path={`${path}/:${PIPELINE_ID_PARAM}`}>
						<Pipeline />
					</Route>
					<Route path={path}>
						<Pipelines />
					</Route>
				</Switch>
			</Suspense>
		</AiLabPage>
	);
}
