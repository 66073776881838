import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	useCrudCreate,
	useCrudDelete,
	useCrudEdit,
	useCrudLoadingMessage,
} from '@cb/product-react/Components/Crud/UseCrud';
import CrudPage, { CrudPageProps, CrudPageRef } from '@cb/product-react/Components/Crud/CrudPage';
import {
	useRepositoryService,
	BasicRepository,
	RepositoryForCreate,
	RepositoryForUpdate,
} from '@cb/product-react/Services/RepositoryService';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import { PageContent, Title } from '@cb/product-react/Components/Layout/CommonPageComponents';
import EditRepositoryModal from './Modal/EditRepositoryModal';
import NewRepositoryModal from './Modal/NewRepositoryModal';
import { AuthMethodDetailsMap, ProviderDetailsMap } from '../Credentials/Credentials';
import copy from 'copy-to-clipboard';
import useGetPlatformResourceIdentifier, {
	PlatformResourceType,
} from '@cb/product-react/Hooks/UseGetPlatformResourceIdentifier';
import { useArrayQueryParam } from '@cb/product-react/Hooks/UseArrayQueryParam';
import { useRepositoryCredentialsService } from '@cb/product-react/Services/RepositoryCredentialsService';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';
import styled from 'styled-components';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';
import Chips, { ChipsProps } from '@cb/solaris-react/Components/Input/Select/Chips';
import { SelectOption } from '@cb/solaris-react/Components/Input/Select/Select';
import { ModalWidths } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import { renderFolderNamespaceContainer } from '@cb/product-react/Components/Crud/FolderNamespaceContainer';
import { showMoveToNamespaceModalModal } from '../../../Modals/MoveToNamespaceModal';
import { MakeUpdatePathEntity, usePathService } from '@cb/product-react/Services/PathService';
import MaterialIconWithText from '@cb/solaris-react/Components/Content/MaterialIconWithText';
import { RepositoryDetailsFragmentDoc } from '@cb/product-react/Graphql/__generated__/graphql';

const CRUD_DISPLAY_NAME = 'repository';

export default function Repositories() {
	const crudPageRef = useRef<CrudPageRef<BasicRepository>>(null);

	const getPlatformResourceIdentifier = useGetPlatformResourceIdentifier();
	const organisationId = useRequiredOrganisationId();
	const repositoryService = useRepositoryService();
	const pathService = usePathService();
	const repositoryCredentialsService = useRepositoryCredentialsService();
	const [paramCreds, updateParamCreds] = useArrayQueryParam('creds');

	const [filterCreds, setFilterCreds] = useState<SelectOption<string>[]>([]);

	useEffect(() => {
		repositoryCredentialsService
			.getAllRepositoryCredentialsForDropdown(organisationId)
			.data.then((result) => {
				const options: SelectOption<string>[] = result.map((x) => {
					return {
						value: x.id,
						display: x.name,
						key: x.id,
					};
				});
				setFilterCreds(options);
			})
			.catch((error) => {
				ErrorUtils.handleError(error, 'Failed to load repository credentials');
			});
	}, [organisationId, repositoryCredentialsService]);

	const handleChipsUpdated = (selected: SelectOption<string>[]) => {
		const selectedMetamodelIds = selected.map((x) => x.value);
		updateParamCreds(selectedMetamodelIds);
	};

	const fetchPaginatedCrudEntities = useCallback(
		(query?: string) => {
			return repositoryService.fetchPaginatedRepositories(organisationId, query, paramCreds, 999);
		},
		[repositoryService, organisationId, paramCreds],
	);

	const createCrudEntity = useCallback(
		(entity: RepositoryForCreate) => {
			return repositoryService.createRepository(entity, organisationId);
		},
		[repositoryService, organisationId],
	);

	const editCrudEntity = useCallback(
		(entity: RepositoryForUpdate): Promise<BasicRepository> => {
			const repoForUpdate: RepositoryForUpdate = {
				id: entity.id,
				uri: entity.uri,
				name: entity.name,
				path: entity.path,
				repositoryCredentialsId: entity.repositoryCredentialsId,
			};
			return repositoryService.updateRepository({
				...repoForUpdate,
				organisationId,
			});
		},
		[repositoryService, organisationId],
	);

	const deleteCrudEntity = useCallback(
		(entity: BasicRepository) => {
			return repositoryService.delete(entity.id).response;
		},
		[repositoryService],
	);

	const showMoveToFolderModal = useCallback(
		async (entity: BasicRepository) => {
			// Pull out the root namespace
			const rootNamespace = crudPageRef.current?.getNamespaceTree();
			if (!rootNamespace) {
				return;
			}

			const existingFolders: string[] = await repositoryService.getNamespacesForOrg(organisationId).response;

			const allFolders: string[] = [
				rootNamespace.path,
				...existingFolders.filter((x) => x !== rootNamespace.path),
			];

			const currentFolder = allFolders.find((x) => x === entity.path);

			showMoveToNamespaceModalModal({
				current: currentFolder ?? rootNamespace.path,
				items: allFolders,
				onMoveRequested: async (namespace) => {
					entity.path = namespace;
					const updated = await editCrudEntity({
						id: entity.id,
						path: namespace,
					});
					crudPageRef.current?.crudObject?.setEntities((entities) => {
						return entities.map((x) => (x.id === updated.id ? updated : x));
					});
				},
				title: 'Move to folder',
			});
		},
		[editCrudEntity, organisationId, repositoryService],
	);

	const handleRenameFolderOrItem = useCallback(
		async (currentPath: string, newPath: string) => {
			// Pull out the root namespace
			const rootNamespace = crudPageRef.current?.getNamespaceTree();
			if (!rootNamespace) {
				return [];
			}

			// Find the node to rename
			const currentNamespace = rootNamespace.find(currentPath);
			if (!currentNamespace) {
				return [];
			}

			const entitiesToUpdate: BasicRepository[] = [];

			currentNamespace.renamePath(newPath, (namespace) => {
				if (namespace.item) {
					// Update the path of the entity (it should be the path of the parent folder)
					namespace.item.path = namespace.path.substring(0, namespace.path.lastIndexOf('/')) || '/';
					entitiesToUpdate.push(namespace.item);
				}
			});

			const updatedEntities = await pathService.updatePaths(
				'Repository',
				entitiesToUpdate as MakeUpdatePathEntity<BasicRepository>[],
				RepositoryDetailsFragmentDoc,
				'RepositoryDetails',
			);

			return updatedEntities;
		},
		[pathService],
	);

	const itemOptions = useMemo<CrudPageProps<BasicRepository>['itemOptions']>(
		() => ({
			fields: (entity) => [
				{
					children: (
						<NameBlock>
							<span>{entity.name}</span>
							<div>
								<span>Created by: {entity.owner?.name ?? 'Unknown'}</span>
							</div>
						</NameBlock>
					),
				},
				{
					children: <span>{entity.provider ? ProviderDetailsMap[entity.provider].name : 'Unknown'}</span>,
				},
				{
					children: (
						<span>
							{entity.repositoryCredentials?.authMethod
								? AuthMethodDetailsMap[entity.repositoryCredentials.authMethod].name
								: 'Public'}
						</span>
					),
				},
			],
			primaryActions: (entity) => [
				{
					children: 'Test connection',
					onClick: () => {
						alert(`Todo! - ${entity.name}`);
					},
				},
			],
			secondaryActions: (entity) => [
				{
					children: <MaterialIconWithText icon="link" text="Copy platform URI" />,
					callback: () => {
						const uri = getPlatformResourceIdentifier(PlatformResourceType.REPOSITORY, entity.id);
						if (copy(uri)) {
							QuickToast.success('Copied to clipboard\n\n' + uri);
						} else {
							QuickToast.error('Failed to copy to clipboard');
						}
					},
				},
				{
					children: <MaterialIconWithText icon="drive_file_move" text="Move to folder" />,
					callback: () => showMoveToFolderModal(entity),
				},
			],
		}),
		[getPlatformResourceIdentifier, showMoveToFolderModal],
	);

	const { isCreating, createFunction } = useCrudCreate(
		createCrudEntity,
		(props) => {
			const namespaces = [
				'/',
				...(crudPageRef.current
					?.getNamespaceTree()
					?.getAllDescendants()
					?.filter((x) => !x.item) // filter out the leaf nodes
					.map((x) => x.path) ?? []),
			];
			return <NewRepositoryModal {...props} organisationId={organisationId} namespaces={namespaces} />;
		},
		{ maxWidth: ModalWidths.LARGE },
	);
	const { isEditing, editFunction } = useCrudEdit(
		editCrudEntity,
		(props) => <EditRepositoryModal {...props} organisationId={organisationId} />,
		{ maxWidth: ModalWidths.LARGE },
	);
	const { isDeleting, deleteFunction } = useCrudDelete(deleteCrudEntity, (x) => x.name);

	const loadingMessage = useCrudLoadingMessage(CRUD_DISPLAY_NAME, { isCreating, isEditing, isDeleting });

	// Ensure the list is in the same order as they were selected (map paramCreds instead of filtering filterCreds)
	const currentSelectedCreds = useMemo(
		() => paramCreds.map((x) => filterCreds.find((y) => y.value === x)).filter((x) => x !== undefined),
		[paramCreds, filterCreds],
	);

	return (
		<PageContent blockingSpinnerMessage={loadingMessage}>
			<Title>Repositories</Title>
			<StyledChips
				label="Credentials"
				options={filterCreds}
				currentOptions={currentSelectedCreds}
				update={handleChipsUpdated}
			/>
			<CrudPage<BasicRepository>
				localStorageId="crud.repositories"
				ref={crudPageRef}
				itemOptions={itemOptions}
				crudProps={{
					fetch: fetchPaginatedCrudEntities,
					create: createFunction,
					edit: editFunction,
					remove: deleteFunction,
				}}
				entityDisplayName={CRUD_DISPLAY_NAME}
				namespaceSelector={(entity: BasicRepository) => {
					return (entity.path?.replace(/\/$/, '') ?? '') + '/' + entity.name;
				}}
				renderNamespace={renderFolderNamespaceContainer}
				onRenameNamespaceRequested={handleRenameFolderOrItem}
			/>
		</PageContent>
	);
}

// This syntax is required to get the correct generics through for the styled component
const StyledChips = styled(Chips as (props: ChipsProps<string>) => JSX.Element)`
	flex-grow: 1;

	label {
		margin-top: 0;
	}
`;

const NameBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	> span {
		font-weight: 700;
	}

	> div {
		margin-top: 0.25rem;
		font-size: 0.8em;
		display: flex;
		align-items: center;
		gap: 4px;
		color: ${({ theme }) => theme.palette.shade4};
	}
`;
