import React from 'react';
import AiLabPage from '../../../Components/Layout/AiLabPage';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import AccessTokens from './AccessTokens/AccessTokens';
import PipelineRunners from './PipelineRunners/PipelineRunners';
import { HeaderBar } from '@cb/product-react/Components/Layout/HeaderBar';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import { getOrganisationLink } from '@cb/product-react/Utils/NavigationUtils';
import { PageWrapper } from '@cb/product-react/Components/Layout/CommonPageComponents';
import DiagramTabButton from '@cb/product-react/Components/Layout/TopNav/DiagramTabButton';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';

enum SettingsTab {
	PipelineRunners = '/settings/pipeline-runners',
	AccessTokens = '/settings/access-tokens',
}

export default function SettingsPage() {
	const { path, url } = useRouteMatch();
	const history = useHistory();
	const organisationId = useRequiredOrganisationId();

	const getTab = (newTab: SettingsTab) => {
		return getOrganisationLink(organisationId, newTab);
	};

	return (
		<AiLabPage pageTitle="Settings" projectRoute={ProjectRoutes.Settings}>
			<PageWrapper>
				<HeaderBar>
					<DiagramTabButton
						selected={history.location.pathname.endsWith(SettingsTab.PipelineRunners)}
						to={getTab(SettingsTab.PipelineRunners)}
					>
						Pipeline Runners
					</DiagramTabButton>
					<DiagramTabButton
						selected={history.location.pathname.endsWith(SettingsTab.AccessTokens)}
						to={getTab(SettingsTab.AccessTokens)}
					>
						Access Tokens
					</DiagramTabButton>
				</HeaderBar>
				<Switch>
					<Route path={`${path}/pipeline-runners`}>
						<PipelineRunners />
					</Route>
					<Route path={`${path}/access-tokens`}>
						<AccessTokens />
					</Route>
					<Route path={path}>
						<Redirect to={`${url}/pipeline-runners`} />
					</Route>
				</Switch>
			</PageWrapper>
		</AiLabPage>
	);
}
