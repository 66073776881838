import { BasicBot, BasicBotValidation, BotForUpdate } from '@cb/product-react/Services/BotService';
import Input from '@cb/solaris-react/Components/Input/Input';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import { Variant, Scheme } from '@cb/solaris-react/Constants/System';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import React, { useCallback, useMemo, useState } from 'react';

interface EditBotModalProps {
	onClose?: () => void;
	current: BasicBot;
	update: (entity: BotForUpdate) => void;
}

export default function EditBotModal(props: EditBotModalProps) {
	const { onClose, update, current } = props;
	const [name, setName] = useState(current.name ?? '');

	const { applyValidation, validateAll, validateField, isValid } = useValidation<{ name: string }>();

	const updateBot = useCallback(() => {
		if (!validateAll({ name })) return;
		update({
			id: current.id,
			name,
		});
	}, [current, name, update, validateAll]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					onClose?.();
					ModalManager.hideModal();
				},
				variant: Variant.quiet,
				text: 'Close',
			},
			{
				callback: updateBot,
				scheme: Scheme.info,
				text: 'Save',
				disabled: !isValid,
			},
		],
		[updateBot, isValid, onClose],
	);

	return (
		<ModalContent
			title="Edit bot"
			actions={actions}
			body={
				<div style={{ width: 'min(400px, 90vw)' }}>
					<Input
						label="Bot name"
						value={name}
						onChange={(val: string) => {
							setName(val);
							validateField('name', val);
						}}
						onEnterPressed={updateBot}
						validation={applyValidation('name', BasicBotValidation.name)}
					/>
				</div>
			}
		/>
	);
}
