import React, { Suspense } from 'react';
import AiLabPage from '../../../Components/Layout/AiLabPage';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { METAMODEL_ID_PARAM } from '@cb/product-react/Hooks/UseParam';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';
import InlineSpinner from '@cb/solaris-react/Components/Loading/InlineSpinner';
const Metamodels = React.lazy(() => import('./Metamodels'));
const Metamodel = React.lazy(() => import('./Metamodel/Metamodel'));

export default function MetamodelsPage() {
	const { path } = useRouteMatch();
	return (
		<AiLabPage pageTitle="Metamodels" projectRoute={ProjectRoutes.Metamodels}>
			<Suspense fallback={<InlineSpinner overlay />}>
				<Switch>
					<Route path={`${path}/:${METAMODEL_ID_PARAM}`}>
						<Metamodel />
					</Route>
					<Route path={path}>
						<Metamodels />
					</Route>
				</Switch>
			</Suspense>
		</AiLabPage>
	);
}
