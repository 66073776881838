import React, { useEffect } from 'react';
import { projectSideMenuLinksComponentGenerator } from '../../Components/Layout/SideMenu/ProjectSideMenu';
import useBurgerMenu from '../../Hooks/UseBurgerMenu';
import Page from '@cb/product-react/Components/Layout/Page';
import OrgDelineatedPage from '@cb/product-react/Components/Layout/OrgDelineatedPage';
import { ProjectRoutes, updateLastUsedProjectRoute } from '../../Utils/NavigationUtils';

interface AiLabProps {
	pageTitle: string;
	children?: React.ReactNode;
	raw?: boolean;
	projectRoute?: ProjectRoutes;
}
export default function AiLabPage(props: AiLabProps) {
	const { raw, projectRoute } = props;
	const burgerMenuItems = useBurgerMenu();

	useEffect(() => {
		if (projectRoute) {
			updateLastUsedProjectRoute(projectRoute);
		}
	}, [projectRoute]);

	return (
		<OrgDelineatedPage>
			<Page
				pageTitle={props.pageTitle}
				sideMenuLinksComponentGenerator={projectSideMenuLinksComponentGenerator}
				burgerMenuItems={burgerMenuItems}
				raw={raw}
			>
				{props.children}
			</Page>
		</OrgDelineatedPage>
	);
}
