import { BOT_ID_PARAM, useParam } from '@cb/product-react/Hooks/UseParam';
import React from 'react';
import styled from 'styled-components';

export default function Bot() {
	const botId = useParam(BOT_ID_PARAM);
	return <Title className="title">The {botId} bot</Title>;
}

const Title = styled.span`
	font-size: calc(${(props) => props.theme.typography.xl} * 2);
	color: ${(props) => props.theme.secondary};
	font-weight: 700;
`;
