import React, { useMemo } from 'react';
import { BasicPipelineRunner } from '@cb/product-react/Services/PipelineRunnerService';
import useConfiguration from '@cb/product-react/Hooks/UseConfiguration';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import { Variant } from '@cb/solaris-react/Constants/System';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import { PropsWithClassName } from '@cb/solaris-react/Utility/PropUtils';
import Codeblock from '@cb/solaris-react/Components/Content/Codeblock';

export type RunnerInstallationModalProps = PropsWithClassName<{
	pipelineRunner: BasicPipelineRunner;
}>;

const getDockerCompose = (platformUrl: string) =>
	`version: '3'
services:
  runner:
    image: registry.gitlab.com/codebots-public/package-repository/botpipelines-runner:2.0.0
    command: ['docker']
    volumes:
      - /var/run/docker.sock:/var/run/docker.sock
    environment:
      PLATFORM_URL: ${platformUrl}
      HELPER_IMAGE: registry.gitlab.com/codebots-public/package-repository/botpipelines-helper:2.0.0
      ACCESS_TOKEN: ##### insert runner token here #####
`;

export default function RunnerInstallationModal(props: RunnerInstallationModalProps) {
	const { pipelineRunner } = props;

	const config = useConfiguration();

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					ModalManager.hideModal();
				},
				variant: Variant.quiet,
				text: 'Close',
			},
		],
		[],
	);

	const handleCopyToClipboard = (didCopy: boolean) => {
		if (didCopy) {
			QuickToast.success('Copied to clipboard');
		} else {
			QuickToast.error('Failed to copy to clipboard');
		}
	};

	return (
		<ModalContent
			title={pipelineRunner.name ?? ''}
			actions={actions}
			body={
				<div style={{ width: 'min(580px, 90vw)' }}>
					<div>
						<p>Use the following Docker Compose file to start a runner.</p>
						<p>
							<strong>Ensure you substitute in the runner token.</strong>
						</p>
						<Codeblock
							onCopyToClipboard={handleCopyToClipboard}
							text={getDockerCompose(config.serviceConfig.platformCoreApiRoot)}
						/>
					</div>
				</div>
			}
		/>
	);
}
