import React from 'react';
import AiLabPage from '../../../Components/Layout/AiLabPage';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import Repositories from './Repositories/Repositories';
import Credentials from './Credentials/Credentials';
import { HeaderBar } from '@cb/product-react/Components/Layout/HeaderBar';
import { getOrganisationLink } from '@cb/product-react/Utils/NavigationUtils';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import DiagramTabButton from '@cb/product-react/Components/Layout/TopNav/DiagramTabButton';
import { PageWrapper } from '@cb/product-react/Components/Layout/CommonPageComponents';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';

enum RepositoryTab {
	Repositories = '/repositories',
	Credentials = '/repositories/credentials',
}

export default function RepositoriesPage() {
	const { path } = useRouteMatch();
	const history = useHistory();
	const organisationId = useRequiredOrganisationId();

	const getTab = (newTab: RepositoryTab) => {
		return getOrganisationLink(organisationId, newTab);
	};

	return (
		<AiLabPage pageTitle="Repositories" projectRoute={ProjectRoutes.Repositories}>
			<PageWrapper>
				<HeaderBar>
					<DiagramTabButton
						selected={history.location.pathname.endsWith(RepositoryTab.Repositories)}
						to={getTab(RepositoryTab.Repositories)}
					>
						Repositories
					</DiagramTabButton>
					<DiagramTabButton
						selected={history.location.pathname.endsWith(RepositoryTab.Credentials)}
						to={getTab(RepositoryTab.Credentials)}
					>
						Credentials
					</DiagramTabButton>
				</HeaderBar>
				<Switch>
					<Route path={`${path}/credentials`}>
						<Credentials />
					</Route>
					<Route path={path}>
						<Repositories />
					</Route>
				</Switch>
			</PageWrapper>
		</AiLabPage>
	);
}
