import React, { Suspense } from 'react';
import AiLabPage from '../../../Components/Layout/AiLabPage';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MODEL_ID_PARAM } from '@cb/product-react/Hooks/UseParam';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';
import InlineSpinner from '@cb/solaris-react/Components/Loading/InlineSpinner';
const Models = React.lazy(() => import('./Models'));
const Model = React.lazy(() => import('./Model/Model'));

export default function ModelsPage() {
	const { path } = useRouteMatch();
	return (
		<AiLabPage pageTitle="Models" projectRoute={ProjectRoutes.Models}>
			<Suspense fallback={<InlineSpinner overlay />}>
				<Switch>
					<Route path={`${path}/:${MODEL_ID_PARAM}`}>
						<Model />
					</Route>
					<Route path={path}>
						<Models />
					</Route>
				</Switch>
			</Suspense>
		</AiLabPage>
	);
}
