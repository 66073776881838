import React, { PropsWithChildren, useState } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import styled from 'styled-components';
import { useDiagramService } from '@cb/product-react/Services/Diagram/DiagramService';
import { useHistory, useLocation } from 'react-router-dom';
import {
	InvalidDiagramObjectExceptionCollection,
	MultipleRootsException,
} from '@cb/diagram-editor-react/Services/DiagramLoader';
import Icon from '@cb/solaris-react/Components/Content/Icon';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import { ModalWidths } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import { Scheme } from '@cb/solaris-react/Constants/System';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import ToastManager from '@cb/solaris-react/Utility/ToastManager';
import { SerialisedObjectWithoutChildren } from '@cb/diagram-editor-react/Types/PersistenceTypes';
import { SerialisedDiagramObject } from '@cb/diagram-editor-react/Types/SerialisedTypes';
import { darken } from '@cb/solaris-react/Constants/Colors';
import ErrorMessage from '@cb/solaris-react/Components/Content/ErrorMessage';

type WithDiagramId = {
	diagramId?: string;
};

export type DiagramErrorBoundaryProps = PropsWithChildren<WithDiagramId>;

export type FallbackComponentProps = FallbackProps & WithDiagramId;

export type InvalidShapesErrorProps = FallbackComponentProps & {
	clearDiagram: (nodeIds?: string[]) => Promise<void>;
};

export default function DiagramEditorBoundary(props: DiagramErrorBoundaryProps) {
	const { children } = props;
	const location = useLocation();
	return (
		<ErrorBoundary
			key={location.pathname}
			FallbackComponent={(fallbackProps) => <FallbackComponent {...fallbackProps} {...props} />}
		>
			{children}
		</ErrorBoundary>
	);
}

function MultipleRootsError(props: InvalidShapesErrorProps) {
	const roots = (props.error as MultipleRootsException).getNodes();
	let keepRoot = roots[0];
	// Find the root with the most children
	roots.forEach((root) => {
		if (root.children.length > keepRoot.children.length) {
			keepRoot = root;
		}
	});
	// Ideally, one of the roots has children and the rest have none.
	const activeRoots = roots.filter((root) => root !== keepRoot && root.children.length > 0);
	const hasSingularActiveRoot = activeRoots.length === 0;
	const message = hasSingularActiveRoot ? (
		<p>Luckily all the other roots have no children, so we can just delete them.</p>
	) : (
		<>
			<p>
				Unfortunately, more than one of your roots has children, so we cannot automatically delete them. Please
				contact someone in Codebots support to determine why. Potential causes include:
			</p>
			<ul>
				<li>
					A model dependency version conflict (more than one version of the same dependency) is leading to two
					versions of this diagram trying to load at once.
					<br />
					<Highlight>
						Ensuring there&apos;s only one version of this model in the dependency chain may resolve this
						issue.
					</Highlight>
				</li>
				<li>A model save in a pipeline forced a second root to be created.</li>
				<li>An unforseen diagram state led to a new root being created and children were created in it.</li>
			</ul>
			<h4>Roots and their children:</h4>
			<StyledTable>
				<thead>
					<tr>
						<th>Class ID</th>
						<th>Uuid</th>
						<th>Children</th>
					</tr>
				</thead>
				<tbody>
					{roots.map((root) => (
						<tr key={root.uuid}>
							<td>{root.data.libraryId}</td>
							<td>{root.uuid}</td>
							<td>{root.children.length}</td>
						</tr>
					))}
				</tbody>
			</StyledTable>
		</>
	);
	return (
		<>
			<h3>Multiple diagram roots detected for this diagram.</h3>
			<ErrorMessageContainer>
				<StyledInvalidShapesError>{message}</StyledInvalidShapesError>
				{hasSingularActiveRoot && (
					<Button
						scheme={Scheme.warning}
						onClick={() => props.clearDiagram(roots.filter((x) => x !== keepRoot).map((x) => x.uuid))}
					>
						Delete extra roots
					</Button>
				)}
			</ErrorMessageContainer>
		</>
	);
}

function InvalidShapesError(props: InvalidShapesErrorProps) {
	const errors = (props.error as InvalidDiagramObjectExceptionCollection).getErrors();
	const invalidNodesMap = new Map<string, SerialisedDiagramObject[]>();
	errors
		.map((e) => e.getNode())
		.forEach((node) => {
			if (!node.shapeData) return;
			const shapeData = JSON.parse(node.shapeData) as SerialisedObjectWithoutChildren;
			const serialisedObject: SerialisedDiagramObject = { ...shapeData, uuid: node.id, children: [] };
			const existingArray = invalidNodesMap.get(shapeData.data.libraryId);
			if (existingArray) {
				existingArray.push(serialisedObject);
			} else {
				invalidNodesMap.set(shapeData.data.libraryId, [serialisedObject]);
			}
		});
	return (
		<>
			<h3>Invalid diagram objects detected</h3>
			<ErrorMessageContainer>
				<StyledInvalidShapesError>
					<p>
						Diagram objects that cannot be matched to a metamodel element have been detected. Potential
						causes include:
					</p>
					<ul>
						<li>
							Most likely: a class was renamed in the metamodel. Model elements are soft linked to their
							corresponding class in the metamodel by name. This identifier also includes a hash of the
							class&apos; unique identifier (uuid). Renaming a class will result in any instances of that
							class in the diagram to become invalid.
							<br />
							<Highlight>
								<i>You could try renaming the class back.</i>
							</Highlight>
						</li>
						<li>
							Similar to the above, if you copy and paste a class in your metamodel, although the name is
							the same the uuid will have changed. This will change the identifier of the class in the
							metamodel and result in any instances of that class in the diagram to become invalid.
						</li>
						<li>
							You may be seeing this error because the diagram engine could not load your metamodel at
							all. In that instance, <b>every class in the diagram will be invalid.</b>
							<br />
							<Highlight>
								<i>If you suspect this might be the case, don&apos;t clear your diagram!</i>
							</Highlight>
						</li>
					</ul>
					<h4>Invalid object details:</h4>
					<StyledTable>
						<thead>
							<tr>
								<th>Class ID</th>
								<th>Count</th>
							</tr>
						</thead>
						<tbody>
							{Array.from(invalidNodesMap.entries()).map(([id, nodes]) => (
								<tr key={id}>
									<td>{nodes[0].data.libraryId}</td>
									<td>{nodes.length}</td>
								</tr>
							))}
						</tbody>
					</StyledTable>
				</StyledInvalidShapesError>
				<Button scheme={Scheme.warning} onClick={() => props.clearDiagram(errors.map((e) => e.getNode().id))}>
					Delete all invalid objects
				</Button>
			</ErrorMessageContainer>
		</>
	);
}

function FallbackComponent(props: FallbackComponentProps) {
	const { resetErrorBoundary, diagramId } = props;
	const diagramService = useDiagramService();
	const [clearingDiagram, setClearingDiagram] = useState(false);
	const history = useHistory();

	const clearDiagram = async (nodeIds?: string[]) => {
		if (props.diagramId) {
			ModalManager.createAndShowModal({
				title: 'Confirm',
				text: `Are you sure you want to  ${
					nodeIds ? `delete ${nodeIds.length} diagram objects` : 'clear the entire diagram'
				}? There is absolutely no way to recover.`,
				maxWidth: ModalWidths.MEDIUM,
				actions: [
					{
						text: 'Yes',
						scheme: Scheme.error,
						callback: async () => {
							ModalManager.hideModal();
							if (!diagramId) {
								console.error('Diagram ID is not set');
								return;
							}
							setClearingDiagram(true);
							try {
								await diagramService.clearDiagram(diagramId, nodeIds);
								resetErrorBoundary();
							} catch {
								ToastManager.showError({ text: 'An error occurred clearing diagram.' });
							} finally {
								setClearingDiagram(false);
							}
						},
					},
					{
						text: 'No',
						scheme: Scheme.secondary,
						callback: () => ModalManager.hideModal(),
					},
				],
			});
		}
		return Promise.resolve();
	};

	const errorName = props.error.name || props.error.constructor.name;

	let view = (
		<>
			<p>We could not load your diagram. Perhaps the information below might help you diagnose the issue.</p>
			<p>
				<strong>{errorName}</strong>: {props.error.message}
			</p>
		</>
	);

	if (props.error instanceof MultipleRootsException) {
		view = <MultipleRootsError {...props} clearDiagram={clearDiagram} />;
	}

	if (props.error instanceof InvalidDiagramObjectExceptionCollection) {
		view = <InvalidShapesError {...props} clearDiagram={clearDiagram} />;
	}

	return (
		<ErrorMessage>
			{view}
			{clearingDiagram ? <LoadingSpinner text="Clearing diagram" overlay={true} /> : null}
			<BackButton scheme={Scheme.secondary} onClick={() => history.goBack()}>
				Back
			</BackButton>
		</ErrorMessage>
	);
}

const StyledInvalidShapesError = styled.div`
	ul {
		li {
			margin-bottom: ${(props) => props.theme.spacing.xs};
		}
	}
`;

const BackButton = styled(Button)`
	margin-top: ${(props) => props.theme.spacing.md};
`;

const ErrorMessageContainer = styled.div`
	padding: ${(props) => props.theme.spacing.xl};
	background: ${(props) => props.theme.palette.shade7};
	max-width: calc(${(props) => props.theme.sizing.xl} * 3);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${(props) => props.theme.spacing.lg};
`;

const StyledTable = styled.table`
	width: 100%;

	background: ${(props) => props.theme.primary};
	padding: ${(props) => props.theme.spacing.sm};

	th {
		text-align: left;
		border-bottom: 1px solid ${(props) => props.theme.palette.shade5};
	}

	th,
	td {
		padding: ${(props) => props.theme.spacing.xs} ${(props) => props.theme.spacing.md};
	}

	tr:nth-child(even) {
		background: ${(props) => props.theme.palette.shade7};
	}
`;

const Highlight = ({ children }: { children: React.ReactNode }) => {
	return (
		<HighlightContainer>
			<Icon name="information" />
			<i>{children}</i>
		</HighlightContainer>
	);
};

const HighlightContainer = styled.div`
	margin-top: ${(props) => props.theme.spacing.xs};
	background: ${(props) => props.theme.palette.success};
	padding: ${(props) => props.theme.spacing.xs} ${(props) => props.theme.spacing.sm};
	border-radius: ${(props) => props.theme.spacing.sm};
	color: ${(props) => props.theme.schemes.success.text};

	display: flex;
	align-items: flex-start;

	.icon {
		margin-right: ${(props) => props.theme.spacing.xs};
		color: ${(props) => darken(props.theme.palette.success, 0.5)};
	}
`;
