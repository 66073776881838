import { BasicAccessTokenValidation, AccessTokenForCreate } from '@cb/product-react/Services/AccessTokenService';
import TimeUtils, { TimeFormat } from '@cb/common-ts/TimeUtils';
import DatePicker from '@cb/solaris-react/Components/Input/DatePicker';
import Input from '@cb/solaris-react/Components/Input/Input';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import { Variant, Scheme } from '@cb/solaris-react/Constants/System';
import useValidation from '@cb/solaris-react/Hooks/UseValidation';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import React, { useCallback, useMemo, useState } from 'react';

interface NewAccessTokenModalProps {
	onClose?: () => void;
	create: (entity: AccessTokenForCreate) => void;
}

export default function NewAccessTokenModal(props: NewAccessTokenModalProps) {
	const { onClose, create: create } = props;
	const [description, setDescription] = useState('');
	const [expiry, setExpiry] = useState<Date | null>(null);

	const { applyValidation, validateField, validateAll } = useValidation<{ description: string; expires: string }>();

	const createAccessToken = useCallback(() => {
		const expiresStr = expiry ? TimeUtils.getFormattedTime(expiry, TimeFormat.DATE_NUMERIC) : '';
		if (
			!validateAll({
				description,
				expires: expiresStr,
			})
		) {
			return;
		}
		create({
			description,
			expires: expiresStr,
		});
	}, [create, description, expiry, validateAll]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					onClose?.();
					ModalManager.hideModal();
				},
				variant: Variant.quiet,
				text: 'Close',
			},
			{
				callback: createAccessToken,
				scheme: Scheme.info,
				text: 'Create',
			},
		],
		[createAccessToken, onClose],
	);

	return (
		<ModalContent
			title="New access token"
			actions={actions}
			body={
				<div style={{ width: 'min(400px, 90vw)' }}>
					<Input
						label="Description"
						value={description}
						autoFocus
						onChange={(val: string) => {
							setDescription(val);
							validateField('description', val);
						}}
						validation={applyValidation('description', BasicAccessTokenValidation.description)}
					/>
					<DatePicker
						label="Expires at (UTC)"
						value={expiry}
						dateFormat="yyyy-MM-dd"
						onChange={(val) => {
							setExpiry(val);
							validateField(
								'expires',
								val ? TimeUtils.getFormattedTime(val, TimeFormat.DATE_NUMERIC) : undefined,
							);
						}}
						validation={applyValidation('expires', BasicAccessTokenValidation.expires)}
					/>
				</div>
			}
		/>
	);
}
