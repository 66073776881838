/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import AILab from './Components/AILab';
import '@cb/product-react/Utils/SortUtils';
import '@cb/product-react/Utils/VersionUtils';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<AILab />
	</React.StrictMode>,
);
