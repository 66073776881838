import React from 'react';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import { useQueryParam } from '@cb/diagram-editor-react/Hooks/UseQueryParam';
import {
	FROM_ORG_SWITCHER_QUERY_PARAM,
	getOrganisationLink,
	getOrganisationRoute,
} from '@cb/product-react/Utils/NavigationUtils';
import { Redirect } from 'react-router-dom';

export enum OrganisationRoutes {
	BotList = '/bots',
	NewBot = '/new-bot',
}

export enum ProjectRoutes {
	// old routes
	Reference = '/reference',
	Importers = '/importers',
	MetaModel = '/metamodel',
	Model = '/model',
	Learn = '/learn',
	Experiment = '/experiment',
	Terminology = '/terminology',
	Versions = '/versions',

	// same routes as in the new UI
	Settings = '/settings',

	// new routes
	Repositories = '/repositories',
	Metamodels = '/metamodels',
	Models = '/models',
	Visual = '/visual',
	Bots = '/bots',
	Pipelines = '/pipelines',
	Raw = '/raw',
	DependencyGraph = '/dependency-graph',
}

export enum MetamodelDiagramTabs {
	Diagram = 'diagram',
	Checkpoints = 'checkpoints',
	Versions = 'versions',
	Preview = 'preview',
}
export enum ModelDiagramTabs {
	Diagram = 'diagram',
	Checkpoints = 'checkpoints',
	Versions = 'versions',
}
export enum PipelineDiagramTabs {
	Diagram = 'diagram',
	Checkpoints = 'checkpoints',
	Versions = 'versions',
	Dependencies = 'dependencies',
	RunHistory = 'run-history',
	RunLogs = 'run-logs',
}
export enum VisualLibraryTabs {
	Diagram = 'diagram',
	Checkpoints = 'checkpoints',
	Versions = 'versions',
}

export enum UnauthenticatedRoutes {
	DiagramTest = '/diagram-test',
}

export const AUTO_SELECT_LATEST_VERSION_QUERY_PARAM = 'selectLatest';
export const IS_NEW_VERSION_QUERY_PARAM = 'newVersion';

export function getAiLabRoute(route: ProjectRoutes) {
	return getOrganisationRoute(route);
}

export function getAiLabLink(organisationId: string, route: ProjectRoutes) {
	return getOrganisationLink(organisationId, route);
}

export function getUnauthenticatedRoute(route: UnauthenticatedRoutes) {
	return `/unauthenticated${route}/:tab?`;
}

export const LAST_USED_METAMODEL_TAB_KEY = 'lastUsedMetamodelTab';
export const LAST_USED_MODEL_TAB_KEY = 'lastUsedModelTab';
export const LAST_USED_EXPERIMENT_TAB_KEY = 'lastUsedExperimentTab';
export const LAST_USED_IMPORTER_TAB_KEY = 'lastUsedImporterTab';
export const LAST_USED_VERSION_TAB_KEY = 'lastUsedVersionTab';

export const DEFAULT_ROUTE = ProjectRoutes.Bots;
export const RedirectToDefault = () => {
	const organisationId = useRequiredOrganisationId();
	let route = DEFAULT_ROUTE;
	const param = useQueryParam(FROM_ORG_SWITCHER_QUERY_PARAM);
	if (param === 'true') {
		route = getLastUsedProjectRoute();
	}
	return <Redirect to={getOrganisationLink(organisationId, route)} />;
};

export const LAST_USED_PROJECT_ROUTE_KEY = 'lastUsedProjectRoute';
export const updateLastUsedProjectRoute = (route: ProjectRoutes) => {
	localStorage.setItem(LAST_USED_PROJECT_ROUTE_KEY, route);
};

export const getLastUsedProjectRoute = (): ProjectRoutes => {
	const routeString = localStorage.getItem(LAST_USED_PROJECT_ROUTE_KEY) ?? DEFAULT_ROUTE;
	return isProjectRoute(routeString) ? routeString : DEFAULT_ROUTE;
};

export const isProjectRoute = (route: string): route is ProjectRoutes => {
	return Object.values<string>(ProjectRoutes).includes(route);
};
