import TimeUtils, { TimeFormat } from '@cb/common-ts/TimeUtils';
import { IScheme } from '@cb/solaris-react/Constants/Colors';
import { ITheme } from '@cb/solaris-react/Constants/Theme';
import useInheritedTheme from '@cb/solaris-react/Hooks/UseInheritedTheme';

type RunnerLastAccessed = { lastContacted?: string | null };

export type RunnerLastAccessedData = {
	lastContactedAgoInSeconds?: number;
	lastContactedReadable: string;
	lastContactedLocalTime: string;
	statusColor: string;
	statusScheme: IScheme;
	isOnline: boolean;
};

export const getRunnerLastAccessed = (theme: ITheme, runner: RunnerLastAccessed): RunnerLastAccessedData => {
	const lastContactedAgoInSeconds = runner.lastContacted
		? Math.abs(TimeUtils.getLocalDayjs(runner.lastContacted).diff(new Date(), 'seconds'))
		: undefined;

	const lastContactedReadable = runner.lastContacted ? TimeUtils.getLocalFromNow(runner.lastContacted) : 'Never';
	const lastContactedLocalTime = runner.lastContacted
		? TimeUtils.getLocalFormattedTime(
				runner.lastContacted,
				`${TimeFormat.DATE_NUMERIC} ${TimeFormat.TIME_12_HOUR_DETAILED} [(local time)]`,
			)
		: 'Never';

	let statusColor = theme.palette.error;
	let statusScheme = theme.schemes.error;
	let isOnline = false;

	if (lastContactedAgoInSeconds !== undefined) {
		if (lastContactedAgoInSeconds <= 60) {
			statusColor = theme.palette.success;
			statusScheme = theme.schemes.success;
			isOnline = true;
		} else if (lastContactedAgoInSeconds <= 300) {
			statusColor = theme.palette.warning;
			statusScheme = theme.schemes.warning;
		}
	}

	return {
		lastContactedAgoInSeconds,
		lastContactedReadable,
		lastContactedLocalTime,
		statusColor,
		statusScheme,
		isOnline,
	};
};

export default function useRunnerLastAccessed(runner: RunnerLastAccessed): RunnerLastAccessedData {
	const theme = useInheritedTheme();
	return getRunnerLastAccessed(theme, runner);
}
