import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import {
	BasicRepositoryCredentials,
	RepositoryCredentialsData,
	RepositoryCredentialsForUpdate,
	useRepositoryCredentialsService,
} from '@cb/product-react/Services/RepositoryCredentialsService';
import { AuthMethodDetailsMap, ProviderDetailsMap } from '../Credentials';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';
import { CredentialData } from './NewCredentialsModal';
import Input from '@cb/solaris-react/Components/Input/Input';
import TextArea from '@cb/solaris-react/Components/Input/TextArea';
import { ModalAction, ModalWidths } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import { Variant, Scheme, Size } from '@cb/solaris-react/Constants/System';
import modalManager from '@cb/solaris-react/Utility/ModalManager';
import { PropsWithClassName } from '@cb/solaris-react/Utility/PropUtils';
import PasswordInput from '@cb/solaris-react/Components/Input/PasswordInput';
import Codeblock from '@cb/solaris-react/Components/Content/Codeblock';
import { AuthMethod } from '@cb/product-react/Graphql/__generated__/graphql';

export type EditCredentialsModalProps = PropsWithClassName<{
	current: BasicRepositoryCredentials;
	update: (entity: RepositoryCredentialsForUpdate) => Promise<void>;
}>;

export default function EditCredentialsModal(props: EditCredentialsModalProps) {
	const { current, update } = props;

	const credentialsService = useRepositoryCredentialsService();

	const [credentialData, setCredentialData] = useState<RepositoryCredentialsData>();

	const [name, setName] = useState<string>(current.name ?? '');
	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [sshKey, setSshKey] = useState<string>('');

	useEffect(() => {
		try {
			const { response } = credentialsService.getCredentialData(current.id);
			response
				.then((creds) => {
					setCredentialData(creds);
					setUsername(creds.basic?.username ?? '');
				})
				.catch((err) => {
					throw err;
				});
		} catch (error) {
			ErrorUtils.handleError(error, 'Failed to fetch credential details');
		}
	}, [current.id, credentialsService]);

	const editCredentials = useCallback(() => {
		const data: CredentialData = {};

		if (current.authMethod === AuthMethod.SSH && sshKey.trim() != '') {
			// SSH key has changed
			data.ssh = {
				privateKey: sshKey,
			};
		} else if (current.authMethod == AuthMethod.BASIC && (password.trim() != '' || username.trim() != '')) {
			data.basic = {
				username,
				password,
			};
		}

		update({
			id: current.id,
			authMethod: current.authMethod,
			name,
			data: Object.keys(data).length > 0 ? JSON.stringify(data) : undefined,
		}).catch((err) => {
			throw err;
		});
	}, [current, name, password, sshKey, update, username]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				text: 'Close',
				variant: Variant.quiet,
				disabled: !credentialData,
				callback: () => {
					modalManager.hideModal();
				},
			},
			{
				text: 'Save',
				scheme: Scheme.info,
				disabled: !credentialData,
				callback: editCredentials,
			},
		],
		[credentialData, editCredentials],
	);

	return (
		<StyledEditCredentialsModal
			className={props.className}
			title="Edit credentials"
			actions={actions}
			body={
				<>
					<Input
						label="Name"
						value={name}
						onChange={setName}
						disabled={!credentialData}
						onEnterPressed={editCredentials}
					/>
					<Input
						label="Provider (cannot be changed)"
						value={current.provider ? ProviderDetailsMap[current.provider].name : ''}
						disabled
					/>
					<Input
						label="Authentication Method (cannot be changed)"
						value={current.authMethod ? AuthMethodDetailsMap[current.authMethod].name : ''}
						disabled
					/>
					{current.authMethod == AuthMethod.SSH && (
						<>
							<TextArea
								label="Private Key"
								value={sshKey}
								onChange={setSshKey}
								placeholder="Hidden for security reasons. Changing this will override the existing private key."
								disabled={!credentialData}
							/>
							{credentialData?.ssh ? (
								<>
									<CodeValue>
										Public Key (for existing private key)
										<Codeblock text={credentialData.ssh.publicKey} />
									</CodeValue>
									<CodeValue>
										MD5 Fingerprint (for existing private key)
										<Codeblock text={credentialData.ssh.fingerprintMd5} />
									</CodeValue>
									<CodeValue>
										SHA256 Fingerprint (for existing private key)
										<Codeblock text={credentialData.ssh.fingerprintSha256} />
									</CodeValue>
								</>
							) : (
								<LoadingSpinner size={Size.small} text="Loading private key information..." />
							)}
						</>
					)}
					{current.authMethod == AuthMethod.BASIC && (
						<>
							<Input
								label="Username"
								value={username}
								onChange={setUsername}
								disabled={!credentialData}
							/>
							<PasswordInput
								label="Password"
								value={password}
								onChange={setPassword}
								placeholder="Hidden for security reasons. Changing this will override the existing password."
								disabled={!credentialData}
							/>
							{!credentialData?.basic && (
								<LoadingSpinner size={Size.small} text="Loading credential information..." />
							)}
						</>
					)}
				</>
			}
		></StyledEditCredentialsModal>
	);
}

export const CodeValue = styled.div`
	${({ theme }) => css`
		margin: ${theme.spacing.sm} 0;
	`}
`;

const StyledEditCredentialsModal = styled(ModalContent)`
	width: ${ModalWidths.LARGE};
`;
