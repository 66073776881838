import React, { useCallback, useMemo } from 'react';
import { PageContent, Title } from '@cb/product-react/Components/Layout/CommonPageComponents';
import {
	useCrudCreate,
	useCrudDelete,
	useCrudEdit,
	useCrudLoadingMessage,
} from '@cb/product-react/Components/Crud/UseCrud';
import CrudPage, { CrudPageProps } from '@cb/product-react/Components/Crud/CrudPage';
import {
	usePipelineRunnerService,
	PipelineRunnerForCreate,
	PipelineRunnerForUpdate,
	BasicPipelineRunner,
} from '@cb/product-react/Services/PipelineRunnerService';
import NewPipelineRunnerModal from './Modals/NewPipelineRunnerModal';
import EditPipelineRunnerModal from './Modals/EditPipelineRunnerModal';
import RevealRunnerTokenModal from './Modals/RevealRunnerTokenModal';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import RunnerInstallationModal from './Modals/RunnerInstallationModal';
import { getRunnerLastAccessed } from '../../../../Hooks/UseRunnerLastAccessed';
import { ModalWidths } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import Tooltip from '@cb/solaris-react/Components/Interactive/Tooltip/Tooltip';
import useInheritedTheme from '@cb/solaris-react/Hooks/UseInheritedTheme';
import modalManager from '@cb/solaris-react/Utility/ModalManager';
import IndicatorText from '@cb/solaris-react/Components/Content/IndicatorText';
import MaterialIconWithText from '@cb/solaris-react/Components/Content/MaterialIconWithText';

const CRUD_DISPLAY_NAME = 'pipeline runner';

export default function PipelineRunners() {
	const theme = useInheritedTheme();
	const pipelineRunnerService = usePipelineRunnerService();

	const organisationId = useRequiredOrganisationId();

	const fetchPaginatedCrudEntities = useCallback(
		(query?: string) => {
			return pipelineRunnerService.fetchPaginatedPipelineRunners(organisationId, query);
		},
		[pipelineRunnerService, organisationId],
	);

	const createCrudEntity = useCallback(
		(entity: PipelineRunnerForCreate) => {
			return pipelineRunnerService.createPipelineRunner(entity, organisationId);
		},
		[pipelineRunnerService, organisationId],
	);

	const editCrudEntity = useCallback(
		(entity: PipelineRunnerForUpdate): Promise<BasicPipelineRunner> => {
			return pipelineRunnerService.updatePipelineRunner({
				...entity,
				organisationId,
			});
		},
		[pipelineRunnerService, organisationId],
	);

	const deleteCrudEntity = useCallback(
		(entity: BasicPipelineRunner) => {
			return pipelineRunnerService.delete(entity.id).response;
		},
		[pipelineRunnerService],
	);

	const itemOptions = useMemo(
		() =>
			({
				fields: (pipelineRunner) => {
					const runnerLastAccessed = getRunnerLastAccessed(theme, pipelineRunner);
					return [
						{
							children: (
								<IndicatorText scheme={runnerLastAccessed.statusScheme}>
									{pipelineRunner.name}
								</IndicatorText>
							),
						},
						{
							children: pipelineRunner.isShared ? 'Shared' : 'Private',
						},
						{
							children: (
								<Tooltip text={`Last contacted ${runnerLastAccessed.lastContactedLocalTime}`}>
									<div>{runnerLastAccessed.lastContactedReadable}</div>
								</Tooltip>
							),
						},
					];
				},
				primaryActions: (pipelineRunner) => [
					{
						children: 'Reveal token',
						onClick: () => {
							modalManager.createAndShowModal({
								allowWrapperClickToClose: false,
								content: <RevealRunnerTokenModal pipelineRunner={pipelineRunner} />,
							});
						},
						disabled: organisationId !== pipelineRunner.organisationId,
					},
				],
				secondaryActions: (pipelineRunner) =>
					organisationId === pipelineRunner.organisationId
						? [
								{
									children: <MaterialIconWithText icon="article" text="Installation instructions" />,
									callback: () => {
										modalManager.createAndShowModal({
											allowWrapperClickToClose: false,
											content: <RunnerInstallationModal pipelineRunner={pipelineRunner} />,
										});
									},
								},
							]
						: false,
			}) as CrudPageProps<BasicPipelineRunner>['itemOptions'],
		[organisationId, theme],
	);

	const { isCreating, createFunction } = useCrudCreate(
		createCrudEntity,
		(props) => <NewPipelineRunnerModal {...props} />,
		{ maxWidth: ModalWidths.LARGE },
	);
	const { isEditing, editFunction } = useCrudEdit(editCrudEntity, (props) => <EditPipelineRunnerModal {...props} />, {
		maxWidth: ModalWidths.LARGE,
	});
	const { isDeleting, deleteFunction } = useCrudDelete(deleteCrudEntity, (x) => x.name);

	const loadingMessage = useCrudLoadingMessage(CRUD_DISPLAY_NAME, { isCreating, isEditing, isDeleting });

	return (
		<PageContent blockingSpinnerMessage={loadingMessage}>
			<Title>Pipeline Runners</Title>
			<CrudPage<BasicPipelineRunner>
				itemOptions={itemOptions}
				crudProps={{
					fetch: fetchPaginatedCrudEntities,
					create: createFunction,
					edit: editFunction,
					remove: deleteFunction,
				}}
				entityDisplayName={CRUD_DISPLAY_NAME}
			/>
		</PageContent>
	);
}
