import React, { useState } from 'react';
import DiagramEditorBoundary from '../../Diagram/DiagramErrorBoundary';
import { BlankPage } from '@cb/product-react/Components/Layout/Page';
import TestDiagram from './TestDiagram';
import { DiagramFetch, isDiagramFetchArray } from '@cb/diagram-editor-react/Services/DiagramLoader';
import styled from 'styled-components';

export default function DiagramTestPage() {
	const [savedDiagrams, setSavedDiagrams] = useState<DiagramFetch[]>();
	const [error, setError] = useState<string>();
	const [textareaValue, setTextareaValue] = useState<string>('');

	let content: React.ReactNode;
	if (error) {
		content = <StyledCentered>{error}</StyledCentered>;
	} else {
		content = (
			<StyledCentered>
				<input
					type="file"
					onChange={(e) => {
						const file = e.target.files?.[0];
						if (!file) return;
						const reader = new FileReader();
						reader.onload = (e) => {
							const result = e.target?.result;
							if (typeof result !== 'string') return;
							const parsed = JSON.parse(result) as unknown;
							if (!isDiagramFetchArray(parsed)) {
								setError("File doesn't contain a valid diagram");
								return;
							}
							setSavedDiagrams(parsed);
						};
						reader.readAsText(file);
					}}
				/>
				<textarea
					placeholder="Paste diagram JSON here"
					onChange={(e) => setTextareaValue(e.target.value)}
					value={textareaValue}
					rows={15}
					cols={40}
				/>
				<button
					onClick={() => {
						try {
							const parsed = JSON.parse(textareaValue) as unknown;
							if (!isDiagramFetchArray(parsed)) {
								setError("Text doesn't contain a valid diagram");
								return;
							}
							setSavedDiagrams(parsed);
						} catch (e) {
							if (e instanceof Error) {
								setError(e.message);
							} else {
								setError('Unknown error');
							}
						}
					}}
				>
					Load from text
				</button>
			</StyledCentered>
		);
	}
	return (
		<BlankPage pageTitle="Diagram Test">
			{savedDiagrams && (
				<DiagramEditorBoundary diagramId={undefined}>
					<TestDiagram savedDiagrams={savedDiagrams} />
				</DiagramEditorBoundary>
			)}
			{!savedDiagrams && content}
		</BlankPage>
	);
}

const StyledCentered = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	gap: 1rem;
`;
