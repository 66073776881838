import React from 'react';
import styled from 'styled-components';
import Diagram from '@cb/diagram-editor-react/Components/Diagram';
import ComboPanel from '@cb/diagram-editor-react/Components/ComboPanel/ComboPanel';
import RightPanel from '@cb/diagram-editor-react/Components/RightPanel';
import { JoyrideId } from '../../Onboarding/JoyrideId';
import { MetaModelJoyrideId } from '../../Onboarding/MetaModelSteps';
import LoadingSpinner from '@cb/solaris-react/Components/Loading/LoadingSpinner';
import useDiagramStoreValue from '@cb/diagram-editor-react/Hooks/DiagramStoreSubscription/UseDiagramStoreValue';

interface EntityDiagramProps {
	propertiesPanel?: React.ReactNode;
	canvasSelectedPanel?: React.ReactNode;
	onMouseMove?: React.MouseEventHandler<HTMLDivElement>;
}

export default function EntityDiagram(props: EntityDiagramProps) {
	const resyncing = useDiagramStoreValue('diagramResyncing');
	const rootObjectFilters = useDiagramStoreValue('rootObjectFilters');

	return (
		<Container onMouseMove={props.onMouseMove}>
			{resyncing && <LoadingSpinner overlay />}
			<ComboPanel
				dropzoneBuffer={{ left: LeftPanelWidth }}
				objectFilters={rootObjectFilters}
				className={JoyrideId.LeftPanel}
			/>
			<Diagram />
			<RightPanel
				propertiesPanel={props.propertiesPanel}
				canvasSelectedPanel={props.canvasSelectedPanel}
				className={MetaModelJoyrideId.RightPanel}
			/>
		</Container>
	);
}

const LeftPanelWidth = 256;

const Container = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
	flex-grow: 1;
	overflow: hidden;
`;
