import React, { useCallback, useMemo, useRef } from 'react';
import {
	useCrudCreate,
	useCrudDelete,
	useCrudEdit,
	useCrudLoadingMessage,
} from '@cb/product-react/Components/Crud/UseCrud';
import CrudPage, { CrudPageProps, CrudPageRef } from '@cb/product-react/Components/Crud/CrudPage';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import { PageContent, PageWrapper, Title } from '@cb/product-react/Components/Layout/CommonPageComponents';
import { BotForCreate, BasicBot, useBotService, BotForUpdate } from '@cb/product-react/Services/BotService';
import NewBotModal from './Modals/NewBotModal';
import EditBotModal from './Modals/EditBotModal';
import { renderFolderNamespaceContainer } from '@cb/product-react/Components/Crud/FolderNamespaceContainer';
import { showMoveToNamespaceModalModal } from '../../Modals/MoveToNamespaceModal';
import { MakeUpdatePathEntity, usePathService } from '@cb/product-react/Services/PathService';
import MaterialIconWithText from '@cb/solaris-react/Components/Content/MaterialIconWithText';
import { BotDetailsFragmentDoc } from '@cb/product-react/Graphql/__generated__/graphql';

const CRUD_DISPLAY_NAME = 'bot';

export default function Bots() {
	const crudPageRef = useRef<CrudPageRef<BasicBot>>(null);

	const organisationId = useRequiredOrganisationId();
	const botService = useBotService();
	const pathService = usePathService();

	const fetchCrudEntities = useCallback(
		(query?: string) => {
			return botService.fetchPaginatedBots(organisationId, query, 999);
		},
		[botService, organisationId],
	);

	const createCrudEntity = useCallback(
		(entity: BotForCreate): Promise<BasicBot> => {
			return botService.createBot(entity, organisationId);
		},
		[botService, organisationId],
	);

	const editCrudEntity = useCallback(
		(entity: BotForUpdate): Promise<BasicBot> => {
			return botService.updateBot({
				...entity,
				organisationId,
			});
		},
		[botService, organisationId],
	);

	const deleteCrudEntity = useCallback(
		(entity: BasicBot) => {
			return botService.delete(entity.id).response;
		},
		[botService],
	);

	const showMoveToFolderModal = useCallback(
		async (entity: BasicBot) => {
			// Pull out the root namespace
			const rootNamespace = crudPageRef.current?.getNamespaceTree();
			if (!rootNamespace) {
				return;
			}

			const existingFolders: string[] = await botService.getNamespacesForOrg(organisationId).response;

			const allFolders: string[] = [
				rootNamespace.path,
				...existingFolders.filter((x) => x !== rootNamespace.path),
			];

			const currentFolder = allFolders.find((x) => x === entity.path);

			showMoveToNamespaceModalModal({
				current: currentFolder ?? rootNamespace.path,
				items: allFolders,
				onMoveRequested: async (namespace) => {
					entity.path = namespace;
					const updated = await editCrudEntity({
						id: entity.id,
						path: namespace,
					});
					crudPageRef.current?.crudObject?.setEntities((entities) => {
						return entities.map((x) => (x.id === updated.id ? updated : x));
					});
				},
				title: 'Move to folder',
			});
		},
		[botService, editCrudEntity, organisationId],
	);

	const handleRenameFolderOrItem = useCallback(
		async (currentPath: string, newPath: string) => {
			// Pull out the root namespace
			const rootNamespace = crudPageRef.current?.getNamespaceTree();
			if (!rootNamespace) {
				return [];
			}

			// Find the node to rename
			const currentNamespace = rootNamespace.find(currentPath);
			if (!currentNamespace) {
				return [];
			}

			const entitiesToUpdate: BasicBot[] = [];

			currentNamespace.renamePath(newPath, (namespace) => {
				if (namespace.item) {
					// Update the path of the entity (it should be the path of the parent folder)
					namespace.item.path = namespace.path.substring(0, namespace.path.lastIndexOf('/')) || '/';
					entitiesToUpdate.push(namespace.item);
				}
			});

			const updatedEntities = await pathService.updatePaths(
				'Bot',
				entitiesToUpdate as MakeUpdatePathEntity<BasicBot>[],
				BotDetailsFragmentDoc,
				'BotDetails',
			);

			return updatedEntities;
		},
		[pathService],
	);

	const itemOptions = useMemo<CrudPageProps<BasicBot>['itemOptions']>(
		() => ({
			fields: (entity: BasicBot) => [{ children: entity.name }],
			primaryActions: (entity: BasicBot) => [
				{
					children: 'View pipelines',
					to: `/${organisationId}/pipelines?bot=${entity.id}`,
				},
			],
			secondaryActions: (entity: BasicBot) => [
				{
					children: <MaterialIconWithText icon="drive_file_move" text="Move to folder" />,
					callback: () => showMoveToFolderModal(entity),
				},
			],
		}),
		[organisationId, showMoveToFolderModal],
	);

	const { isCreating, createFunction } = useCrudCreate(createCrudEntity, (props) => {
		const namespaces = [
			'/',
			...(crudPageRef.current
				?.getNamespaceTree()
				?.getAllDescendants()
				?.filter((x) => !x.item) // filter out the leaf nodes
				.map((x) => x.path) ?? []),
		];
		return <NewBotModal {...props} namespaces={namespaces} />;
	});
	const { isEditing, editFunction } = useCrudEdit(editCrudEntity, (props) => <EditBotModal {...props} />);
	const { isDeleting, deleteFunction } = useCrudDelete(deleteCrudEntity, (x) => x.name);

	const loadingMessage = useCrudLoadingMessage(CRUD_DISPLAY_NAME, { isCreating, isEditing, isDeleting });

	return (
		<PageWrapper>
			<PageContent blockingSpinnerMessage={loadingMessage} noHeaderBar>
				<Title>Bots</Title>
				<CrudPage<BasicBot>
					localStorageId="crud.bots"
					ref={crudPageRef}
					itemOptions={itemOptions}
					crudProps={{
						fetch: fetchCrudEntities,
						create: createFunction,
						edit: editFunction,
						remove: deleteFunction,
					}}
					entityDisplayName={CRUD_DISPLAY_NAME}
					editButtonText={`Edit ${CRUD_DISPLAY_NAME} name`}
					namespaceSelector={(entity: BasicBot) => {
						return (entity.path?.replace(/\/$/, '') ?? '') + '/' + entity.name;
					}}
					renderNamespace={renderFolderNamespaceContainer}
					onRenameNamespaceRequested={handleRenameFolderOrItem}
				/>
			</PageContent>
		</PageWrapper>
	);
}
