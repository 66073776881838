import React from 'react';
import AiLabPage from '../../../Components/Layout/AiLabPage';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Bots from './Bots';
import { BOT_ID_PARAM } from '@cb/product-react/Hooks/UseParam';
import Bot from './Bot/Bot';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';

export default function BotsPage() {
	const { path } = useRouteMatch();
	return (
		<AiLabPage pageTitle="Bots" projectRoute={ProjectRoutes.Bots}>
			<Switch>
				<Route path={`${path}/:${BOT_ID_PARAM}`}>
					<Bot />
				</Route>
				<Route path={path}>
					<Bots />
				</Route>
			</Switch>
		</AiLabPage>
	);
}
