import React from 'react';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import {
	CloseSlideoutPanel,
	SetSlideoutPanel,
	SideMenuLinksComponentGenerator,
	StyledItemsContainer,
} from '@cb/product-react/Components/Layout/SideMenu/SideMenu';
import { LinkSideMenuItem } from '@cb/product-react/Components/Layout/SideMenu/SideMenuItem';
import { getAiLabLink, ProjectRoutes } from '../../../Utils/NavigationUtils';
import styled from 'styled-components';
import Icon from '@cb/solaris-react/Components/Content/Icon';
import { transparentize } from '@cb/solaris-react/Constants/Colors';

export interface SideMenuProps {
	setSlideoutPanel: SetSlideoutPanel;
	closeSlideoutPanel: CloseSlideoutPanel;
}

export const projectSideMenuLinksComponentGenerator: SideMenuLinksComponentGenerator = (setSlideout, closeSlideout) => (
	<ProjectSideMenu setSlideoutPanel={setSlideout} closeSlideoutPanel={closeSlideout} />
);

export default function ProjectSideMenu(props: SideMenuProps) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { setSlideoutPanel, closeSlideoutPanel } = props;

	const organisationId = useRequiredOrganisationId();

	const aiLabLink = (link: ProjectRoutes) => getAiLabLink(organisationId, link);

	return (
		<>
			<StyledProjectSwitchIcon>
				<ProjectSwitchIcon>
					<Icon name="tests" />
				</ProjectSwitchIcon>
			</StyledProjectSwitchIcon>
			<StyledItemsContainer>
				<LinkSideMenuItem name="Bots" iconName="bot" link={aiLabLink(ProjectRoutes.Bots)} />
				<LinkSideMenuItem name="Pipelines" iconName="source" link={aiLabLink(ProjectRoutes.Pipelines)} />
				<LinkSideMenuItem name="Metamodels" iconName="workflow" link={aiLabLink(ProjectRoutes.Metamodels)} />
				<LinkSideMenuItem name="Visual" iconName="entity" link={aiLabLink(ProjectRoutes.Visual)} />
				<LinkSideMenuItem name="Models" iconName="flow-connect-type" link={aiLabLink(ProjectRoutes.Models)} />
				<LinkSideMenuItem
					name="Repositories"
					iconName="git-branch"
					link={aiLabLink(ProjectRoutes.Repositories)}
				/>
				<LinkSideMenuItem name="Settings" iconName="gears" link={aiLabLink(ProjectRoutes.Settings)} />
			</StyledItemsContainer>
		</>
	);
}

const ProjectSwitchIcon = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => transparentize(props.theme.palette.success, 0.9)};
	color: ${(props) => props.theme.palette.success};
	cursor: pointer;
	user-select: none;

	:hover {
		background-color: ${(props) => props.theme.palette.shade7};
	}
`;

const StyledProjectSwitchIcon = styled.div`
	width: ${(props) => props.theme.sizing.md};
	height: ${(props) => props.theme.sizing.md};
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${(props) => props.theme.breakpoints.sm}) {
		display: none;
	}
`;
