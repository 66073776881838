import React from 'react';
import { BaseJoyrideStep } from '@cb/product-react/Hooks/UseJoyride';
import { StyledPromptList } from './StyledPromptList';
import { JoyrideId } from './JoyrideId';
import { Scheme } from '@cb/solaris-react/Constants/System';

export const MetaModelJoyrideId = {
	LeftPanel: JoyrideId.LeftPanel,
	Tabs: 'joyride-meta-model-tabs',
	RightPanel: 'joyride-right-panel',
	Preview: 'joyride-meta-model-preview',
	ActionButton: 'joyride-meta-model-action-button',
};

export const steps: BaseJoyrideStep[] = [
	{
		highlight: {
			target: `.${MetaModelJoyrideId.LeftPanel}`,
			allowClickthrough: false,
		},
		prompt: {
			title: 'Building Blocks',
			text: "Drag and drop shapes, connections, and premade shapes to define your element's visual appearance.",
		},
	},
	{
		highlight: {
			target: `#${MetaModelJoyrideId.Tabs}`,
			allowClickthrough: false,
		},
		prompt: {
			title: 'Metamodel Diagrams',
			text: (
				<StyledPromptList>
					<p>Switch between different diagrams.</p>
					<ul>
						<li>
							<p>
								<b>Structural:</b> define the information stored in your model
							</p>
						</li>
						<li>
							<p>
								<b>Visual:</b> design the appearance of your elements
							</p>
						</li>
						<li>
							<p>
								<b>Mapping:</b> connects the Visual elements to the Structural
							</p>
						</li>
					</ul>
				</StyledPromptList>
			),
			placement: 'bottom',
		},
	},
	{
		highlight: {
			target: `.${MetaModelJoyrideId.RightPanel}`,
			allowClickthrough: false,
		},
		prompt: {
			title: 'Properties',
			text: 'View and modify canvas and element properties.',
		},
	},
	{
		highlight: {
			target: `#${MetaModelJoyrideId.ActionButton}`,
			allowClickthrough: false,
		},
		prompt: {
			title: 'When ready...',
			text: 'Commit your metamodel changes to the metamodel repository.',
			scheme: Scheme.brand2,
		},
	},
];
