import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { GqlPaginatedQueryResponse, PaginationResponse } from '@cb/product-react/Services/EntityService';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';
import Button from '@cb/solaris-react/Components/Interactive/Button/Button';
import { Variant } from '@cb/solaris-react/Constants/System';
import { PropsWithClassName } from '@cb/solaris-react/Utility/PropUtils';

export type PaginatorProps<T> = PropsWithClassName<{
	onIsLoadingChanged?: (onIsLoading: boolean) => void;
	onItemsChanged?: (items: T[]) => void;
	showZeroItems?: boolean;
	paginatedFunction: () => GqlPaginatedQueryResponse<T[]>;
}>;

export default function Paginator<T>(props: PaginatorProps<T>) {
	const { paginatedFunction, onIsLoadingChanged, onItemsChanged, showZeroItems } = props;
	const [items, setItems] = useState<T[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [pagination, setPagination] = useState<PaginationResponse<T[]> | undefined>(undefined);

	const fetchItems = useCallback(async () => {
		try {
			setIsLoading(true);
			const { data, pagination: newPagination } = paginatedFunction();
			const items = await data;
			setItems(items);
			setPagination(newPagination);
			setIsLoading(false);
		} catch (err) {
			ErrorUtils.handleError(err, undefined, () => {
				setIsLoading(false);
			});
		}
	}, [paginatedFunction]);

	const fetchMoreItems = useCallback(async () => {
		if (!fetch || !pagination) {
			return;
		}

		try {
			setIsLoading(true);
			const { data, pagination: newPagination } = pagination.loadNextPage();
			const additionalItems = await data;
			setItems((items) => {
				return [...items, ...additionalItems];
			});

			setPagination(newPagination);
			setIsLoading(false);
		} catch (err) {
			ErrorUtils.handleError(err, undefined, () => {
				setIsLoading(false);
			});
		}
	}, [pagination]);

	// Fetch on mount
	useEffect(() => {
		fetchItems().catch((err) => {
			throw err;
		});
	}, [fetchItems]);

	// Notify parent of changes
	useEffect(() => {
		onIsLoadingChanged?.(isLoading);
	}, [isLoading, onIsLoadingChanged]);

	useEffect(() => {
		onItemsChanged?.(items);
	}, [items, onItemsChanged]);

	const paginationTotal = pagination?.total ? pagination.total : 0;

	const showCount = (showZeroItems && items.length === 0) || (!isLoading && items.length > 0);

	return (
		<StyledPaginator className={props.className}>
			{showCount && (
				<Count>
					Showing {items.length} of {paginationTotal} results
				</Count>
			)}
			{items.length < paginationTotal && (
				<LoadMoreButton variant={Variant.quiet} onClick={fetchMoreItems} disabled={isLoading}>
					Load more
				</LoadMoreButton>
			)}
		</StyledPaginator>
	);
}

const Count = styled.p`
	text-align: center;
`;
const LoadMoreButton = styled(Button)``;
const StyledPaginator = styled.div``;
