import { IBurgerMenuItem } from '@cb/product-react/Components/Layout/SideMenu/BurgerMenuItem';
import useOrganisationId from '@cb/product-react/Hooks/UseOrganisationId';
import { getOrganisationLink } from '@cb/product-react/Utils/NavigationUtils';
import { OrganisationRoutes } from '../Utils/NavigationUtils';

export default function useBurgerMenu() {
	const organisationId = useOrganisationId();

	const menuItems: IBurgerMenuItem[] = [];

	if (organisationId) {
		menuItems.push({
			text: 'Projects',
			icon: 'home',
			link: getOrganisationLink(organisationId, OrganisationRoutes.BotList),
		});
	}

	return menuItems;
}
