import React, { ReactNode, useCallback, useMemo, useRef } from 'react';
import { useRequiredOrganisationId } from '@cb/product-react/Hooks/UseOrganisationId';
import {
	useRepositoryCredentialsService,
	BasicRepositoryCredentials,
	RepositoryCredentialsForCreate,
	RepositoryCredentialsForUpdate,
} from '@cb/product-react/Services/RepositoryCredentialsService';
import {
	useCrudCreate,
	useCrudDelete,
	useCrudEdit,
	useCrudLoadingMessage,
} from '@cb/product-react/Components/Crud/UseCrud';
import CrudPage, { CrudPageProps, CrudPageRef } from '@cb/product-react/Components/Crud/CrudPage';
import { PageContent, Title } from '@cb/product-react/Components/Layout/CommonPageComponents';
import EditCredentialsModal from './Modals/EditCredentialsModal';
import NewCredentialsModal from './Modals/NewCredentialsModal';
import DefaultDeleteModal from '@cb/product-react/Components/Crud/DefaultDeleteModal';
import { IconKey } from '@cb/solaris-react/Components/Content/Icon';
import { ModalWidths } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import { Variant } from '@cb/solaris-react/Constants/System';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import { renderFolderNamespaceContainer } from '@cb/product-react/Components/Crud/FolderNamespaceContainer';
import { showMoveToNamespaceModalModal } from '../../../Modals/MoveToNamespaceModal';
import { MakeUpdatePathEntity, usePathService } from '@cb/product-react/Services/PathService';
import MaterialIconWithText from '@cb/solaris-react/Components/Content/MaterialIconWithText';
import {
	AuthMethod,
	RepositoryCredentialsDetailsFragmentDoc,
	RepositoryProvider,
} from '@cb/product-react/Graphql/__generated__/graphql';
import styled from 'styled-components';

const CRUD_DISPLAY_NAME = 'credentials';

export default function Credentials() {
	const crudPageRef = useRef<CrudPageRef<BasicRepositoryCredentials>>(null);

	const organisationId = useRequiredOrganisationId();
	const credentialsService = useRepositoryCredentialsService();
	const pathService = usePathService();

	const fetchPaginatedCrudEntities = useCallback(
		(query?: string) => {
			return credentialsService.fetchPaginatedCredentials(organisationId, query, 999);
		},
		[credentialsService, organisationId],
	);

	const createCrudEntity = useCallback(
		(entity: RepositoryCredentialsForCreate) => {
			return credentialsService.createRepositoryCredentials(entity, organisationId);
		},
		[credentialsService, organisationId],
	);

	const editCrudEntity = useCallback(
		(entity: RepositoryCredentialsForUpdate): Promise<BasicRepositoryCredentials> => {
			return credentialsService.updateRepositoryCredentials({
				...entity,
				organisationId,
			});
		},
		[credentialsService, organisationId],
	);

	const deleteCrudEntity = useCallback(
		(entity: BasicRepositoryCredentials) => {
			return credentialsService.delete(entity.id).response;
		},
		[credentialsService],
	);

	const showMoveToFolderModal = useCallback(
		async (entity: BasicRepositoryCredentials) => {
			// Pull out the root namespace
			const rootNamespace = crudPageRef.current?.getNamespaceTree();
			if (!rootNamespace) {
				return;
			}

			const existingFolders: string[] = await credentialsService.getNamespacesForOrg(organisationId).response;

			const allFolders: string[] = [
				rootNamespace.path,
				...existingFolders.filter((x) => x !== rootNamespace.path),
			];

			const currentFolder = allFolders.find((x) => x === entity.path);

			showMoveToNamespaceModalModal({
				current: currentFolder ?? rootNamespace.path,
				items: allFolders,
				onMoveRequested: async (namespace) => {
					entity.path = namespace;
					const updated = await editCrudEntity({
						id: entity.id,
						path: namespace,
					});
					crudPageRef.current?.crudObject?.setEntities((entities) => {
						return entities.map((x) => (x.id === updated.id ? updated : x));
					});
				},
				title: 'Move to folder',
			});
		},
		[credentialsService, editCrudEntity, organisationId],
	);

	const handleRenameFolderOrItem = useCallback(
		async (currentPath: string, newPath: string) => {
			// Pull out the root namespace
			const rootNamespace = crudPageRef.current?.getNamespaceTree();
			if (!rootNamespace) {
				return [];
			}

			// Find the node to rename
			const currentNamespace = rootNamespace.find(currentPath);
			if (!currentNamespace) {
				return [];
			}

			const entitiesToUpdate: BasicRepositoryCredentials[] = [];

			currentNamespace.renamePath(newPath, (namespace) => {
				if (namespace.item) {
					// Update the path of the entity (it should be the path of the parent folder)
					namespace.item.path = namespace.path.substring(0, namespace.path.lastIndexOf('/')) || '/';
					entitiesToUpdate.push(namespace.item);
				}
			});

			const updatedEntities = await pathService.updatePaths(
				'RepositoryCredentials',
				entitiesToUpdate as MakeUpdatePathEntity<BasicRepositoryCredentials>[],
				RepositoryCredentialsDetailsFragmentDoc,
				'RepositoryCredentialsDetails',
			);

			return updatedEntities;
		},
		[pathService],
	);

	const itemOptions = useMemo<CrudPageProps<BasicRepositoryCredentials>['itemOptions']>(
		() => ({
			fields: (credential) => [
				{
					children: (
						<NameBlock>
							<span>{credential.name}</span>
							<div>
								<span>Created by: {credential.owner?.name ?? 'Unknown'}</span>
							</div>
						</NameBlock>
					),
				},
				{
					children: credential.provider ? ProviderDetailsMap[credential.provider].name : 'Unknown',
				},
				{
					children: credential.authMethod ? AuthMethodDetailsMap[credential.authMethod].name : 'Unknown',
				},
			],
			primaryActions: (credentials) => [
				{
					children: 'View repositories',
					to: `/${organisationId}/repositories?creds=${credentials.id}`,
				},
			],
			secondaryActions: (entity) => [
				{
					children: <MaterialIconWithText icon="drive_file_move" text="Move to folder" />,
					callback: () => showMoveToFolderModal(entity),
				},
			],
		}),
		[organisationId, showMoveToFolderModal],
	);

	const { isCreating, createFunction } = useCrudCreate(
		createCrudEntity,
		(props) => {
			const namespaces = [
				'/',
				...(crudPageRef.current
					?.getNamespaceTree()
					?.getAllDescendants()
					?.filter((x) => !x.item) // filter out the leaf nodes
					.map((x) => x.path) ?? []),
			];
			return <NewCredentialsModal {...props} namespaces={namespaces} />;
		},
		{
			maxWidth: ModalWidths.LARGE,
		},
	);
	const { isEditing, editFunction } = useCrudEdit(editCrudEntity, (props) => <EditCredentialsModal {...props} />, {
		maxWidth: ModalWidths.LARGE,
	});
	const { isDeleting, deleteFunction } = useCrudDelete<BasicRepositoryCredentials>(
		deleteCrudEntity,
		(x) => x.name,
		(props) => {
			if (props.entity.repositories?.length) {
				return (
					<ModalContent
						title={`Delete ${props.entity.name}`}
						actions={[
							{
								callback: () => {
									ModalManager.hideModal();
								},
								variant: Variant.quiet,
								text: 'Cancel',
							},
						]}
						body={
							<p>
								Sorry, this credential cannot be deleted as it is currently in use by at least one
								repository.
							</p>
						}
					/>
				);
			} else {
				return <DefaultDeleteModal {...props} />;
			}
		},
	);

	const loadingMessage = useCrudLoadingMessage(CRUD_DISPLAY_NAME, { isCreating, isEditing, isDeleting });

	return (
		<PageContent blockingSpinnerMessage={loadingMessage}>
			<Title>Credentials</Title>
			<CrudPage<BasicRepositoryCredentials>
				localStorageId="crud.credentials"
				ref={crudPageRef}
				itemOptions={itemOptions}
				crudProps={{
					fetch: fetchPaginatedCrudEntities,
					create: createFunction,
					edit: editFunction,
					remove: deleteFunction,
				}}
				entityDisplayName={CRUD_DISPLAY_NAME}
				namespaceSelector={(entity: BasicRepositoryCredentials) => {
					return (entity.path?.replace(/\/$/, '') ?? '') + '/' + entity.name;
				}}
				renderNamespace={renderFolderNamespaceContainer}
				onRenameNamespaceRequested={handleRenameFolderOrItem}
			/>
		</PageContent>
	);
}

export const AuthMethodDetailsMap: Record<AuthMethod, { name: string; description?: ReactNode; icon: IconKey }> = {
	SSH: {
		name: 'Private Key (SSH)',
		description: (
			<>
				Provide us with a Private Key.
				<br />
				We will use it authenticate against your repository over SSH
			</>
		),
		icon: 'lock',
	},
	BASIC: {
		name: 'Username & Password (HTTPS)',
		description: (
			<>
				Provide us with a Username and Password.
				<br />
				We will use it authenticate against your repository over HTTPS.
			</>
		),
		icon: 'key',
	},
	OAUTH: {
		name: 'TODO',
		description: 'TODO',
		icon: 'gitlab',
	},
};

export const ProviderDetailsMap: Record<RepositoryProvider, { name: string; description?: ReactNode; icon: IconKey }> =
	{
		GENERIC: {
			name: 'Generic',
			description: 'Connect to a Git repository via HTTPS or SSH',
			icon: 'git-branch',
		},
		GITLAB: {
			name: 'GitLab',
			description: 'Connect to an On-premise or cloud-hosted GitLab repository',
			icon: 'gitlab',
		},
		GITHUB: {
			name: 'GitHub',
			description: 'Connect to a GitHub repository',
			icon: 'github',
		},
		BITBUCKET: {
			name: 'Bitbucket',
			description: 'Connect to a Bitbucket repository',
			icon: 'atlassian',
		},
	};

const NameBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	> span {
		font-weight: 700;
	}

	> div {
		margin-top: 0.25rem;
		font-size: 0.8em;
		display: flex;
		align-items: center;
		gap: 4px;
		color: ${({ theme }) => theme.palette.shade4};
	}
`;
