import React, { useEffect, useMemo, useState } from 'react';
import { BasicPipelineRunner, usePipelineRunnerService } from '@cb/product-react/Services/PipelineRunnerService';
import useLoadTracker from '@cb/product-react/Hooks/UseLoadTracker';
import ErrorUtils from '@cb/product-react/Utils/ErrorUtils';
import { QuickToast } from '@cb/product-react/Utils/QuickToast';
import Codeblock from '@cb/solaris-react/Components/Content/Codeblock';
import { ModalAction } from '@cb/solaris-react/Components/Interactive/Modal/Modal';
import ModalContent from '@cb/solaris-react/Components/Interactive/Modal/ModalContent';
import InlineSpinner from '@cb/solaris-react/Components/Loading/InlineSpinner';
import { Variant } from '@cb/solaris-react/Constants/System';
import ModalManager from '@cb/solaris-react/Utility/ModalManager';
import { PropsWithClassName } from '@cb/solaris-react/Utility/PropUtils';

export type RevealRunnerTokenModalProps = PropsWithClassName<{
	pipelineRunner: BasicPipelineRunner;
}>;

export default function RevealRunnerTokenModal(props: RevealRunnerTokenModalProps) {
	const { pipelineRunner } = props;

	const pipelineRunnerService = usePipelineRunnerService();
	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const [revealedRunnerToken, setRevealedRunnerToken] = useState<string>();

	useEffect(() => {
		const loaderId = addLoader();
		pipelineRunnerService
			.revealRunnerToken(pipelineRunner.id)
			.then((revealed) => {
				setRevealedRunnerToken(revealed);
				removeLoader(loaderId);
			})
			.catch((err) => {
				ErrorUtils.handleError(err, 'Failed to reveal access token', () => {
					removeLoader(loaderId);
				});
			});
	}, [pipelineRunnerService, pipelineRunner.id, addLoader, removeLoader]);

	const actions: ModalAction[] = useMemo(
		() => [
			{
				callback: () => {
					ModalManager.hideModal();
				},
				variant: Variant.quiet,
				text: 'Close',
			},
		],
		[],
	);

	const handleCopyToClipboard = (didCopy: boolean) => {
		if (didCopy) {
			QuickToast.success('Copied to clipboard');
		} else {
			QuickToast.error('Failed to copy to clipboard');
		}
	};

	return (
		<ModalContent
			title={pipelineRunner.name ?? ''}
			actions={actions}
			body={
				<div style={{ width: 'min(400px, 90vw)' }}>
					{isLoading ? (
						<InlineSpinner />
					) : (
						<div>
							<p>
								Your runner&apos;s access token is as follows. <strong>Keep it safe!</strong>
							</p>
							<Codeblock onCopyToClipboard={handleCopyToClipboard} text={revealedRunnerToken} />
						</div>
					)}
				</div>
			}
		/>
	);
}
