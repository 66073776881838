import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ProjectRoutes } from '../../../Utils/NavigationUtils';
import PipelinesPage from '../Pipelines/PipelinesPage';

export default function RawPage() {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={`${path}${ProjectRoutes.Pipelines}`}>
				<PipelinesPage raw />
			</Route>
		</Switch>
	);
}
